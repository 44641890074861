import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @media ${({ theme }) => theme.device.lg} {
    max-width: 1024px;
  }
`

interface ContanierProps {
  gray: boolean
  black: boolean
  padded: boolean
  noPaddingTop: boolean
  noPaddingBottom: boolean
  backgroundImg: string
}

export const Container = styled.div<Partial<ContanierProps>>`
  background-color: ${props =>
    props.gray
      ? props.theme.colors.dimGray
      : props.black
        ? props.theme.colors.black
        : props.theme.colors.white
  };
  color: ${props => (props.black || props.gray ? props.theme.colors.white : props.theme.colors.black)};
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding: ${props => props.padded ? '25px 18px' : 0};

  @media ${({ theme }) => theme.device.sm} {
    padding: ${props => props.padded ? '35px 40px' : 0};
  }

  @media ${({ theme }) => theme.device.lg} {
    padding: ${props => props.padded ? '45px 60px' : 0};
  }

  ${props =>
    props.backgroundImg &&
    css`
      background: url(${props.backgroundImg});
    `
  }

  ${props =>
    props.noPaddingTop &&
    css`
      padding-top: 0 !important;
    `
  }

  ${props =>
    props.noPaddingBottom &&
    css`
      padding-bottom: 0 !important;
    `
  }

  &&&:last-child {
    margin-bottom: 50px;

    @media ${({ theme }) => theme.device.sm} {
      margin-bottom: 80px;
    }
  }

  &&& {
    a {
      color: ${props => (props.black || props.gray ? props.theme.colors.white : props.theme.colors.black)};
    }
  }
`

export const QProgramPeopleContainer = styled(Container)`
  height: 220px;
  background-size: cover;
  background-position: bottom;

  @media ${({ theme }) => theme.device.sm} {
    height: 430px;
    background-size: cover;
    background-position: center;
  }

  @media ${({ theme }) => theme.device.lg} {
    background-position: bottom;
    height: 530px;
  }
`

interface TitleProps {
  bigger: boolean
  align: string
  margin: string
}

export const Title = styled.h3<Partial<TitleProps>>`
  width: ${props => props.bigger ? '80%' : '60%'};
  text-align: ${props => props.align ? props.align : 'center'};
  font-size: 1.2em;
  font-weight: 900;
  margin: ${props => props.margin ? props.margin : '0 auto'};

  @media ${({ theme }) => theme.device.xs} {
    width: ${props => props.bigger ? '70%' : '55%'};
  }

  @media ${({ theme }) => theme.device.sm} {
    width: 100%;
    font-size: 1.5em;
  }

  @media ${({ theme }) => theme.device.lg} {
    font-size: 2.0em;
  }
`

export const StyledSpan = styled.span`
  margin-bottom: 1.2em;
  font-size: 1em;
  line-height: 1.1em;
  font-weight: 300;

  &&&:last-child {
    margin-bottom: 0;
  }

  &&& {
    a, strong {
      font-weight: 900;
    }
  }

  @media ${({ theme }) => theme.device.sm} {
    font-size: 1.1em;
  }

  @media ${({ theme }) => theme.device.lg} {
    margin-bottom: 1.4em;
    font-size: 1.5em;
  }
`

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
  margin: 0 auto;
  min-width: 0;
`

export const RewardImage = styled(StyledImage)`
  width: 70%;

  @media ${({ theme }) => theme.device.sm} {
    width: 50%;
  }
`

export const ListItem = styled.li`
  margin-bottom: 1.2em;

  @media ${({ theme }) => theme.device.lg} {
    margin-bottom: 1.4em;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  column-gap: 1.2em;
  margin-top: 1.2em;
  margin-bottom: 1.4em;
`

export const StyledList = styled.ul`
  padding-left: 0;
  margin-left: 15px;
`

export const StyledOlList = styled.ol`
  padding-left: 0;
  margin-left: 20px;
  margin-top: 0;
  margin-bottom: 0;

  &&& li::marker {
    font-size: 1em;

    @media ${({ theme }) => theme.device.sm} {
      font-size: 1.1em;
    }

    @media ${({ theme }) => theme.device.lg} {
      font-size: 1.5em;
    }
  }
`