import { RawTextType } from "../types/PrismicContentTypes";

const formatMultiLineText = (text: string) => {
  const regReplace = new RegExp('\n', 'g')
  let htmlText = text ? text.replace(regReplace, '<br />') : ''

  return htmlText
}

export const formatParagraph = (item: RawTextType) => {
  if (item.spans.length === 0) {
    return formatMultiLineText(item.text)
  }

  let htmlText = item.text
  let offset = 0

  for (const span of item.spans) {
    let append = ''
    let prepend = ''

    if (span.type === 'strong') {
      append = '<strong>'
      prepend = '</strong>'
    } else if (span.type === 'hyperlink') {
      append = `<a href='${span.data.url}' ${span.data.target ? 'target="' + span.data.target + '"' : ''}>`
      prepend = '</a>'
    }

    htmlText = [htmlText.slice(0, span.start + offset), append, htmlText.slice(span.start + offset)].join('')
    offset += append.length
    htmlText = [htmlText.slice(0, span.end + offset), prepend, htmlText.slice(span.end + offset)].join('')
    offset += prepend.length
  }

  return formatMultiLineText(htmlText)
}

